<template>
    <section class="container">
        <section class="about">
            <img class="about__img" src="@/assets/images/wysport.jpeg" alt="sport">
            <div class="about__text">

                <h1 class="about__text--title">Markham, a place called home</h1>
                <p class="about__text--content">Twenty-three years ago, my husband and I decided to choose Markham as a place to settle down. For two decades we have lived here with our beloved daughters, made heartfelt friends, and amicable neighbours. We live in a cozy home and are blessed to live in this beautiful city of Markham.</p>
                
                <h1 class="about__text--title">From an entrepreneur to a special needs educator</h1>
                <p class="about__text--content">During my early years as a first-generation immigrant, my main goal was to pursue a career in entrepreneurship. My trading business entity here was quite successful but soon I realized that's not the only objective I immigrated to Canada for. I wanted to spend more time with my family, be part of the community, and live a meaningful life.
                    <br><br>With those ideas in mind, I took a sharp turn in my career that I felt was more suitable for me. In 2012, I stepped into the special education field.  For different district school boards such as York Region, Durham, Durham Catholic, and Toronto. My job gave me opportunities to communicate with families from various backgrounds and to understand the problems they have been facing. I have then developed a passion for community well-being.
                </p>

                 <h1 class="about__text--title">From an amateur host to a media professional</h1>
                <p class="about__text--content">In 2017, I picked up interest as a broadcasting show host. It saw a lot of success and was a great experience for me. After that, I produced and hosted a radio program named “Charming Toronto” that aired on FM 105.9 radio station every week. Through this show, I was able to bring people together from a variety of professions, backgrounds and experiences. I am also very enthusiastic about promoting the Canadian values of diversity, equity, democracy, and inclusiveness.</p>

                <h1 class="about__text--title">An active community leader</h1>
                <p class="about__text--content">Volunteering has played an important part in my life. I work as Secretary-General of Huaqiao University Alumni Association Of Canada. And I always engage in hosting community events such as the new year celebrations, charity fundraising, and various sporting activities. The fulfilling and worthwhile experience motivates me to move forward.</p>

                <h1 class="about__text--title">Please support me!</h1>
                <p class="about__text--content">I am a strong leader, a brave advocator, a problem solver, and a good listener with a caring and inspiring heart. I am ready to step up to serve my community to the next level. Together, we can make our community stronger and healthier!</p>
            </div>
        </section>
      
  </section>
</template>
<script>
export default {
    name: "AboutMe",
    data(){
        return{
            aboutme: ["About Me", "關於我", "关于我"]
        }
    },
    props: {
        lang: 0,
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';
.container{
    width: 100%;
    // height: fit-content;
    background-color: $background-color;
    padding: 40px 40px;
    
}

.about{
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    height: 100%;
    margin: auto;
    
    // background-color: blue;

    &__img{
        height: 550px;
        border: solid 5px $primary-color;
        align-self: flex-start;
    }

    &__text{
        // background-color: red;

        &--title{
            color: $primary-color;
            font-size: $heading1;
            text-align: left;
            padding: 0px 0px 0px 0px;
            font-weight: 600;
        }
        &--content{
            text-align: left;
            font-size: 22px;
            padding: 10px 0px 40px 0px;
        }

    }
}

@media only screen and (max-width: 1000px){
   
    .container{
        padding: 40px 10px 20px 10px;
    }
    .about{
        width: 90%;
    }
}
@media only screen and (max-width: 800px){
   
    .container{
        padding: 40px 10px 20px 10px;
        justify-content: center;
        align-items: center;
    }
    .about{
        width: 100%;
        flex-direction: column;
        gap: 20px;

        &__img{
        height: 500px;
        align-self: center;
        }

        &__text{
            padding: 0 10px;
            &--title{
                font-size: $heading1;
                text-align: left;
                padding: 10px 0px;
                font-weight: 600;
            }
            &--content{
                text-align: left;
                font-size: 22px;
                padding: 10px 0px;
            }

        }
    }
}
</style>