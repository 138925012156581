<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
    <Message v-if="showMessage" :lang="lang" :messageType="msg"/>
    <Loading v-if="processing" />
  <router-view />


</template>
<script>
import Loading from '@/components/Loading.vue'
import Message from '@/components/Message.vue'
export default {
    name: "App",
    data(){
        return{
            processing: false,
            showMessage: false,
            messageDuration: 0,
            lang: 0,
            msg: 0,
        }
    },
    components: {
        Loading,
        Message
    },
    methods: {
        startTimer(seconds){
            setTimeout(function(){
                this.messageDuration = seconds;
                 if(this.messageDuration > 0){
                    this.startTimer(this.messageDuration-1);
                }else{
                    this.showMessage = false;
                }
       
            }.bind(this), 1000);
        },
        loadScreen(lang, form){
            this.processing = !this.processing;
            
            if(this.processing == false){
                this.lang = lang
                this.msg = form
                this.showMessage = true;
                this.startTimer(2);
                
            }
        }
    },

}
</script>
<style lang="scss">
@import "./assets/styles.scss";


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(37,37,37);
  width: 100%;
  height: 100%;

}

</style>
