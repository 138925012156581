

<template>
  <Navbar :lang="lang" />
  <div class="bar"></div>
    <FocusPage :lang="lang"/>
    <Contact :lang="lang"/>
  
</template>
<script>
import Navbar from '@/components/Navbar2.vue';
import FocusPage from '@/components/focus/Focuspoints.vue';
import Contact from '@/components/home/Contact.vue';
import translate from '@/assets/translate.json'

export default {
    name: "Focus",
    components: {
        Navbar,
        FocusPage,
        Contact
    },
    props:{
        lang: 0,
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/styles.scss';
.bar{
    height: 80px;
    width: 100%;
}

</style>
