<template>
    <section class="container">
        <h1>Hobbies</h1>
        <p>In my free time, I enjoy playing sports, gardening, rock painting, and cooking. Everywhere I go, I wish to learn from my surroundings and grow with my community. I’m highly ambitious and love to jump at any opportunity I can find! 
</p>
        <div class="images">
            <div :style="{'background-image': 'url(' + image1 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image2 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image3 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image4 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image5 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image6 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image7 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image8 + ')'}"></div>
            <div :style="{'background-image': 'url(' + image9 + ')'}"></div>
        </div>
  </section>
</template>
<script>

export default {
    name: "Gallery",
    data(){
        return{
           image1: require("@/assets/images/hobby1.jpeg"),
           image2: require("@/assets/images/hobby2.jpeg"),
           image3: require("@/assets/images/hobby3.jpeg"),
           image4: require("@/assets/images/hobby4.jpeg"),
           image5: require("@/assets/images/hobby5.jpeg"),
           image6: require("@/assets/images/hobby6.jpeg"),
           image7: require("@/assets/images/hobby7.jpeg"),
           image8: require("@/assets/images/hobby8.jpeg"),
           image9: require("@/assets/images/family3.jpeg"),
        }
    },
    props: {
        lang: 0,
    },
    mounted(){


    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';

.container{
    height: fit-content;
    background-color: $primary-color;
    padding: 40px 0px 80px 0px;
   
}
h1{
    text-align: center;
    margin: auto;
    color: white;
    font-size: $heading1;
    padding: 0px 0px 40px 0px;
}

p{
    font-size: 20px;
    padding: 0px 0px 50px 0px;
    color: white;
    text-align: left;
    width: clamp(365px, 75%, 1000px);
    margin: auto;

}
.images{
    display: grid;
    width: fit-content;
    grid-template-columns: 400px 400px 400px;
    margin: auto;
    // background-color: blue;
    justify-items: center;
    align-items: center;
    gap: 30px 30px;
    


    & > div{
        height: 300px;
        width: 100%;
        // width: 400px;
        
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.27);
        background-size: cover;
        background-position: center;
        transition: all .4s;

        // &:hover{
        //     border: solid 4px $accent-color;
        //     filter: brightness(110%);
        //     transform: scale(1.05);
           
        // }

    }
}

@media only screen and (max-width: 1300px){
    .images{
        grid-template-columns: 400px 400px;
    }
}

@media only screen and (max-width: 900px){
    .images{
        grid-template-columns: 400px;
    }
}

@media only screen and (max-width: 500px){
    .images{
        width: 100%;
        grid-template-columns: 1fr;
    }
}
</style>