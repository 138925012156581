import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/templates/HomeEng.vue'
import Hometrad from '../views/templates/HomeTrad.vue'
import Homesimp from '../views/templates/HomeSimp.vue'

import About from '../views/templates/AboutEng.vue'
import Abouttrad from '../views/templates/AboutTrad.vue'
import Aboutsimp from '../views/templates/AboutSimp.vue'

import Focus from '../views/templates/FocusEng.vue'
import Focustrad from '../views/templates/FocusTrad.vue'
import Focussimp from '../views/templates/FocusSimp.vue'

import Useless from '../views/Useless.vue'
import Testnav from '../components/home/Contact.vue'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/ct',
    name: 'homecanto',
    component: Hometrad,
  },
  {
    path: '/zh',
    name: 'homemando',
    component: Homesimp,
  },
  //ABOUT SECTION PAGES
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/ct/about',
    name: 'aboutcanto',
    component: Abouttrad,
  },
  {
    path: '/zh/about',
    name: 'aboutmando',
    component: Aboutsimp,
  },
//FOCUS SECTION PAGES
  {
    path: '/focus',
    name: 'focus',
    component: Focus
  },
  {
    path: '/ct/focus',
    name: 'focustrad',
    component: Focustrad
  },
  {
    path: '/zh/focus',
    name: 'focussimp',
    component: Focussimp
  },
//   {
//     path: '/useless',
//     name: 'useless',
//     component: Useless
//   }
//   {
//     path: '/how-to-vote',
//     name: 'HowtoVote',
//     component: Howtovote,
//   },
    

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})


// const match = window.matchMedia("(pointer:coarse)");
// const isMobile = (match && match.matches);



// router.beforeEach(async (to, from) => {
//     console.log(isMobile)
//     if(to.name == "home" && isMobile){
//         return { name: 'useless' };
//     }
    
// })

export default router
