<template>
    <div class="home">
        <div class="home__text">
            <!-- <p class="home__text--first">New Possibilities</p> -->
            <p class="home__text--second">{{translate.text[0][lang]}}</p>
            <h1 class="home__title">{{translate.text[1][lang]}}</h1>
            <h2 class="home__subtitle">{{translate.text[2][lang]}}</h2>

            <section class="home__buttons">
                <button class="home__buttons--vote" @click="howtoVote()">{{translate.buttons[0][lang]}}</button>
                <button class="home__buttons--contact" @click="$parent.contactScroll()">{{translate.buttons[1][lang]}}</button>
            </section>
        </div>
        
        <img src="@/assets/images/portrait.png" alt="">
    </div>
</template>

<script>
import translate from '@/assets/translate.json';

export default {
    name: "Start",
    data(){
        return{
            translate: translate.intro,
        }
    },
    methods:{
        howtoVote(){
            // window.open("https://www.markham.ca/wps/portal/home/about/2022-Municipal-Election/Ways-to-Vote/02-Ways-To-Vote")
            window.open("https://wangyan.ca/House.php")
        }
    },
    props: {
        lang: 0,
        
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/styles.scss';

.home{
    width: 100%;
    height: 600px;
    background: $background-color;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    & > img{
        display: block;
        width: 420px;
        align-self: flex-end;

    }

    &__text{
        // position: absolute;
        // z-index: 4;
        // top: 50%;
        // left: 10%;
        transform: translateY(-20px);
        height: fit-content;
        padding-left: 40px;
        min-width: 350px;
        background-color: $background-color;
        


        &--first{
            color: $text-color;
            font-size: 3.13em;
            font-weight: 100;
            line-height: 60px;
        }

        &--second{
            color: $text-color;
            font-size: 3.13em;
            font-weight: 600;
            line-height: 50px;
        }
    }

    &__title{
        color: $primary-color;
        font-size: 5em;
        font-weight: 600;
        
    }

    &__subtitle{
        color: $text-color;
        font-size: 2.5em;
        font-weight: 200;
        line-height: 50px;
    }

    &__buttons{
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        &--vote{
            @include button;
            background-color: $primary-color;
            color: white;
            border: none;

            &:hover{
                cursor: pointer;
                background-color: $primary-color-hover;
            }
            
 
        }

        &--contact{
            @include button;
            background: none;
            border: solid 3px $text-color;
            color: $text-color;

            &:hover{
                cursor: pointer;
                background: rgba(255, 255, 255, 0.492);
            }
        }
    }


}

@media screen and (max-width: 1000px) {
    .home{
        flex-direction: column-reverse;
        gap: 0px;
        justify-content: center;
        align-items: center;
        height: fit-content;
        
        &__text{
        //    background: rgb(37,37,37);
            padding: 20px;
            transform: translateY(-50px);

        }

        & > img{
            display: block;
            width: 300px;
            align-self: center;
            margin-top: 10px;
        }

        &__buttons{
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        }
    }

    .intro{
    &__img{
        transform: translateX(-150px);
  
    }
    }
}
</style>