<template>
    <section class="container">
        <h1 class="headingOne">{{title[lang]}}</h1>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est sit amet facilisis magna etiam tempor orci.</p> -->
        <section class="focus">
            <div class="focus__one focuscard">
                <div class="focus__one--img" style="background-image: url('https://www.dreyergroup.ca/wp-content/uploads/2019/04/neighbourhood.jpg');"></div>
                <article>
                <h1 class="focus__one--head">Community Safety</h1>
                <p class="focus__one--text">A strong community is for today's well-being and for tomorrow's prosperity.</p>
                </article>
            </div>
             <div class="focus__two focuscard">
                <div class="focus__two--img" :style="{'background-image': 'url('+require('@/assets/images/mentalhealth.jpeg') + ')'}"></div>
                <article>
                <h1 class="focus__two--head">Mental Health Support</h1>
                <p class="focus__two--text">As a dedicated special needs educator, I see the beauty in different people.</p>
                </article>
           </div>
              <div class="focus__three focuscard">
                <div class="focus__three--img" style="background-image: url('https://upload.wikimedia.org/wikipedia/commons/1/1c/MarkhamCivicCenter5.jpg');"></div>
                <article>
                <h1 class="focus__three--head">Accessible Municipal Services</h1>
                <p class="focus__three--text">Most importantly, as a parent, I believe in building a healthy and safe community.</p>
                </article>            
            </div>
        </section>
  </section>
</template>
<script>
export default {
    name: "Focuspoints",
    props: {
        lang: 0,
    },
    data(){
        return{
            title: ["Why should you vote for Yan Wang?", "你爲什麼要投票給王燕？", "你为什么要投票给王燕？"]
        }
    }

}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';
.container{
    width: 100%;
    height: fit-content;
    background-color: $background-color;
    padding: 50px 40px 80px 40px;
    // min-height: calc(100vh - 80px);

}


.focuscard{
    width: 350px;
    height: 550px;
    background-color: white;
    box-shadow: 0px 0px 3px 2px rgba(146, 146, 146, 0.412);

    & > div{
        border: solid 5px $primary-color;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 300px;
    }

    & > article{
        background-color: $primary-color;
        color: white;
        height: 250px;
        & > h1{
            padding: 20px 10px;
            font-size: 2em;
            // color: $primary-color;
        }

        & > p{
            text-align: left;
            padding: 0 20px;
            font-size:20px;
            padding-bottom: 40px;
            // color: white;
        }
    }
}
.focus{
    display: flex;
    flex-direction: row;

    justify-content: center;
    // align-items: stretch;
    gap: 50px;
    margin-top: 40px;

}

@media only screen and (max-width: 1250px){
    .focus{
        flex-direction: column;
        align-items: center;
    }

    .focuscard{
        display: flex;
        width: 75%;
        height: 350px;

        & > div{
            width: 50%;
            height: 100%;
        }

        & > article{
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 1000px){
    .focuscard{
        width: 690px;
        height: 350px;
    }
}

@media only screen and (max-width: 750px){
    .focus{
        flex-direction: column;
        align-items: center;
    }

    .focuscard{
        display: block;
        width: 350px;
        height: 550px;

        & > div{
            width: 100%;
            height: 300px;
        }

        & > article{
            width: 100%;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

</style>